// const baseURL=`http://localhost:5550/`;
// const baseURL=`http://88.99.163.249:5550/`;
// const baseURL=`https://tsel.h2ndigital.com/`;
// const baseURL=`https://bubblygamez.gameit.in/`;
const baseURL=`/`;
export {baseURL};

const gamesApi=`${baseURL}api/games`;
export {gamesApi};

const loginApi=`${baseURL}api/login`;
export {loginApi};