import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/home",
      element: <HomePage />,
    },
    {
      path:"/",
      element:<LoginPage />
    }
  ]);
  return <RouterProvider router={router} />;
};

export default App;
