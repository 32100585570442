import React, { useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { gamesApi, loginApi } from "../api/api";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HomePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const checkPin = async () => {
    try {
      const number = JSON.parse(localStorage.getItem("pin"));
      const data = { PIN: number };
      const res = await axios.post(loginApi, data);
      if (
        res?.data?.result == "User Login Successfully" ||
        res?.status == 200
      ) {
        localStorage.setItem("pin", JSON.stringify(number));
      } else {
        localStorage.removeItem("pin");
        navigate("/");
      }
    } catch (error) {
      localStorage.removeItem("pin");
      navigate("/");
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(gamesApi);
      setData(res?.data?.result);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  useEffect(() => {
    checkPin();
    fetchDataFromBackend();
  }, []);

  const navigateToGame = (url) => {
    window.location.href = url;
  };

  //Owl Carousel Settings
  const options = {
    loop: true,
    items: 3,
    margin: 0,
    margin: 20,
    center: true, // Center the active item
    // autoWidth: true, // Adjust item width automatically
    nav: false,
    autoplay: true,
    dots: true,
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    autoplayHoverPause: true,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <div className={classes.container}>
      <div className={classes.background_main}>
        <div className={classes.header}>
          <div className={classes.logo_container}></div>
        </div>
        <div className={classes.play_btn_container}>
          <div className={classes.play_btn}>H2N Games</div>
        </div>
      </div>

      <div className={classes.sub_container}>
        <div className={classes.title}>
          <h3>Our Games</h3>
          <div className={classes.underline}></div>
        </div>
        {loading ? (
          <div className={classes.center_loader}>
            <span className={classes.loader}></span>
          </div>
        ) : (
          <>
            <div className={classes.game_carousel_container}>
              <div className={classes.game_carousel_sub_container}>
                <OwlCarousel className="owl-theme" {...options}>
                  {data?.slice(11, 20)?.map((dataItem, i) => {
                    return (
                      <div
                        onClick={() => navigateToGame(dataItem?.gameUrl)}
                        className={classes.carousel_container}
                        key={i}
                      >
                        <div className={classes.game_image}>
                          <img
                            className={classes.game_image_img}
                            src={dataItem?.imageUrl}
                            alt={dataItem?.gameName}
                          />
                        </div>
                        <h3 className={classes.text}>{dataItem?.gameName}</h3>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>

            <div className={classes.games_container}>
              <div className={classes.grid_container}>
                {data?.slice(0, 10)?.map((dataItem, i) => {
                  return (
                    <div
                      className={classes.grid_item}
                      key={i}
                      onClick={() => navigateToGame(dataItem?.gameUrl)}
                    >
                      <img
                        src={dataItem?.imageUrl}
                        alt={dataItem?.gameName}
                        className={classes.image}
                      />
                      <div className={classes.game_name_container}>
                        <div className={classes.game_name_sub_container}>
                          <h3 className={classes.game_name}>
                            {dataItem?.gameName}
                          </h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classes.footer_container}>
              <h3>&copy; Copyright by H2N</h3>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;
