import React, { useState } from "react";
import classes from "./LoginPage.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import { loginApi } from "../api/api";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = { PIN: number };
      const res = await axios.post(loginApi, data);
      setLoading(false);
      console.log(res, "res");
      if (
        res?.data?.result == "User Login Successfully" ||
        res?.status == 200
      ) {
        localStorage.setItem("pin", JSON.stringify(number));
        navigate("/home");
      }
    } catch (error) {
      localStorage.removeItem('pin');
      setLoading(false);
      console.log(error, "error");
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.form_wrapper}>
        <img src="/img1.png" className={classes.img_left} />
        <div className={classes.form_wrapper_left}>
          <h1>Welcome to H2N Games</h1>
          <form className={classes.form_signup} onSubmit={submitHandler}>
            <div className={classes.input_group}>
              <input
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter Pin"
              />
              <i className="fa-solid fa-lock"></i>
            </div>
            <button
              className={classes.form_btn}
              // onClick={submitHandler}
              type="submit"
            >
              Submit
            </button>
            {loading && (
              <div className={classes.center_loader}>
                <span className={classes.loader}></span>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
